<template>
  <div class="app not-found">
    <div class="not-found-in">
      <h1>404 &nbsp; Not &nbsp; Found</h1>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.not-found {
  display: table;
  background-image: url('../assets/img/404bg.png');
  &-in {
    display: table-cell;
    vertical-align: middle;
    h1 {
      text-align: center;
      margin-top: -8%;
      font-size: 60px;
    }
  }
}
</style>
